import React, {useEffect, useState} from 'react'
import {InputAdornment, TextField, Typography} from '@mui/material'
import PendingIcon from '@mui/icons-material/Pending'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {checkGphLogin} from '../../utils/gphFunctions'

const GatewayGphUsdt = (props) => {
    const [gphLogin, setGphLogin] = useState('')
    const [gphLoginStatus, setGphLoginStatus] = useState(0)
    const [gphLoginError, setGphLoginError] = useState('')
    const [tronAddress, setTronAddress] = useState(null)

    const checkGphLoginHandler = (result) => {
        setGphLoginStatus(-1)
        setTronAddress(null)
        setGphLoginError('')
        if (result.status) {
            if (result.status === 200) {
                if (result.data.address) {
                    setGphLoginStatus(1)
                    setTronAddress(result.data.address)
                } else if (result.data.error) {
                    setGphLoginError(result.data.error)
                } else {
                    setGphLoginError('Request error')
                }
            } else {
                setGphLoginError(result.statusText)
            }
        } else {
            setGphLoginError(result.error.message)
        }
    }
    useEffect(() => {
        setGphLoginStatus(0)
        if (gphLogin !== '') {
            checkGphLogin(gphLogin, checkGphLoginHandler)
        }
    }, [gphLogin])

    const handleChange = (event) => {
        const {id, value} = event.target
        switch (id) {
            case 'gphLogin':
                setGphLogin(value)
                break
            default:
                break
        }
    }
    let gphLoginIcon
    if (gphLogin === '') {
        gphLoginIcon = ''
    } else {
        switch (gphLoginStatus) {
            case -1:
                gphLoginIcon = <ErrorOutlineIcon color={'error'}/>
                break
            case 1:
                gphLoginIcon = <CheckCircleOutlineIcon color={'success'}/>
                break
            default:
                gphLoginIcon = <PendingIcon/>
        }
    }

    return <>
        <Typography variant={'h4'} gutterBottom>Шлюз USDT (TRC-20) - Graphene</Typography>
        <Typography gutterBottom><b>Комиссия на пополнение и вывод по 2 USDT.</b></Typography>
        <Typography mt={3} gutterBottom><b>Для пополнения</b> счета введите свой адрес в сети Graphene.</Typography>
        <TextField
            id="gphLogin"
            label="GPH login"
            value={gphLogin}
            onChange={handleChange}
            error={gphLoginStatus < 0}
            helperText={gphLoginError}
            margin="normal"
            InputProps={{
                endAdornment: <InputAdornment position="end">{gphLoginIcon}</InputAdornment>,
            }}
        />
        {tronAddress ? <Typography gutterBottom>Переведите свои <b>USDT (TRC-20)</b> на адрес {tronAddress}</Typography> : ''}
        <Typography mt={3} gutterBottom><b>Для вывода</b> сделайте перевод токенов XCHNG.USDT внутри Graphene на аккаунт
            xchng-usdt в мемо укажите свой кошелек в сети Tron.</Typography>
    </>
}

export default GatewayGphUsdt