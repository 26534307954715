import axios from 'axios'

axios.defaults.withCredentials = true

export const checkGphLogin = async (login, callback) => {
    try {
        callback(await axios.get(`https://api.xchng.finance/gateway/usdt/deposit/${login}`))
    } catch (e) {
        callback({error: e})
    }
}