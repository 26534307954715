import React from 'react'
import {Typography} from "@mui/material";

const NotFound = (props) => {
    return <>
        <Typography variant={'h3'} gutterBottom>Error 404</Typography>
        <Typography variant={'body1'} gutterBottom>Page not found</Typography>
    </>
}

export default NotFound