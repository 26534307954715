import React from 'react'

const VizonatorContext = React.createContext()

const useVizonator = () => {
    const context = React.useContext(VizonatorContext)
    if (!context) {
        throw new Error(`useVizonator must be used within a VizonatorProvider`)
    }
    return context
}

const getVizonator = () => {
    return window.vizonator
}

const VizonatorProvider = (props) => {
    const [count, setCount] = React.useState(0)
    const val = React.useMemo(() => {
        console.log('useMeno')
        console.log(window.vizonator)
        return [count, setCount]
    }, [getVizonator()])
    const value = {
        getVizonator,
        vizonator: getVizonator(),
    }
/*
    const value = React.useMemo(() => ({
        vizonator: window.vizonator,
    }), [window.vizonator])
*/
/*
    if (!window.vizonator) {
        setTimeout(() => {
            VizonatorProvider(props)
        }, 1000)
    }
*/
    return <VizonatorContext.Provider value={value} {...props} />
}

export {VizonatorProvider, useVizonator}
