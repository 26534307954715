import {Route, Routes} from 'react-router-dom'
import './App.css'
import Dashboard from './components/Dashboard'
import NotFound from './components/NotFound'
import About from './components/About'
import Gateways from './components/Gateways/Gateways'
import GatewayGphUsdt from './components/Gateways/GatewayGphUsdt'
import GatewayGphViz from './components/Gateways/GatewayGphViz'
import {VizonatorProvider} from './utils/vizonatorContext'

function App() {
    return <VizonatorProvider>
        <Routes>
            <Route path="/" element={<Dashboard/>}>
                <Route exact path="/" element={<About/>}/>
                <Route path="/gateways" element={<Gateways/>}>
                    <Route exact path="/gateways/gph-usdt" element={<GatewayGphUsdt/>}/>
                    <Route exact path="/gateways/gph-viz" element={<GatewayGphViz/>}/>
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    </VizonatorProvider>
}

export default App
