import React from 'react'
import {Link, Typography} from '@mui/material'

const About = (props) => {
    return <>
        <Typography variant={'h4'} gutterBottom>Шлюзы XCHNG.finance</Typography>
        <Typography gutterBottom>На сайте будут собраны все инструкции и формы для работы со шлюзами XCHNG.</Typography>
        <Typography gutterBottom>По всем вопросам можно обращаться в <Link href={'https://t.me/xchng_chat'} target={'_blank'}
                                                              rel={'nofollow'}>Telegram</Link>.</Typography>
    </>
}

export default About