import React, {useEffect, useState} from 'react'
import {InputAdornment, Link, TextField, Typography} from '@mui/material'
import {checkGphLogin} from '../../utils/gphFunctions'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PendingIcon from '@mui/icons-material/Pending'
import {useVizonator} from '../../utils/vizonatorContext'

const GatewayGphViz = (props) => {
/*
    const {getVizonator, vizonator} = useVizonator()
    const [vizAmount, setVizAmount] = useState(null)
    const [vizAmountStatus, setVizAmountStatus] = useState(0)
    const [vizAmountError, setVizAmountError] = useState('')
    const [depositForm, setDepositForm] = useState(<a href={'https://viz.world/vizonator/'} target={'_blank'}
                                                      rel={'nofollow'}>Vizonator не
        установлен</a>)

    useEffect(() => {
        console.log(getVizonator())
        /!*
                setVizAmountStatus(0)
                setVizAmountError('')
                if (vizAmount === null) {

                } else if (vizAmount <= 0) {
                    setVizAmountStatus(-1)
                    setVizAmountError('Неверное значение')
                }
        *!/
    }, [vizAmount])

    const handleChange = (event) => {
        const {id, value} = event.target
        switch (id) {
            case 'amount':
                setVizAmount(value)
                break
            default:
                break
        }
    }
    let vizAmountIcon
    switch (vizAmountStatus) {
        case -1:
            vizAmountIcon = <ErrorOutlineIcon color={'error'}/>
            break
        case 1:
            vizAmountIcon = <CheckCircleOutlineIcon color={'success'}/>
            break
        default:
            vizAmountIcon = ''
    }
*/
/*
    if (vizonator) {
        setDepositForm(<>
            <TextField
                id="amount"
                label="Количество VIZ"
                value={vizAmount}
                onChange={handleChange}
                error={vizAmountStatus < 0}
                helperText={vizAmountError}
                margin="normal"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{vizAmountIcon}</InputAdornment>,
                }}
            />
        </>)
    }
*/

    return <>
        <Typography variant={'h4'} gutterBottom>Шлюз VIZ - Graphene</Typography>
        <Typography mt={3}><b>Для пополнения торгового счета</b>, переведите свои токены VIZ
            аккаунту <i>gph.xchng</i>, а в заметке укажите <i>log:свой-логин-на-graphene</i> (например:
            log:my-login).</Typography>
        <img src={'https://i.viz.world/0x0/https://i.imgur.com/0p9et4t.png'}/>
        <Typography gutterBottom>При пополнении счета взимается <b>комиссия в размере 10 VIZ</b>, в связи с этим все
            суммы меньше и
            равные комиссии будут восприниматься как пожертвование. Когда будет сформирован рынок, комиссия будет
            периодически меняться в зависимости от цены токена.</Typography>
{/*
        {depositForm}
*/}
        <Typography mt={3}><b>Для вывода средств с биржи</b>, выполните перевод (через вкладку «Отправить») токенов
            XCHNG.VIZ в
            сети Graphene на аккаунт <i>xchng-viz</i>, а в примечании укажите <i>log:свой-логин-на-viz</i> (например:
            log:my-login).</Typography>
        <Typography>В сети Graphene токен имеет 6 знаков после запятой, однако, в сети VIZ он делится всего до 3-х
            знаков. Поэтому при переводе значение из сети Graphene будет обрезано до 3-х знаков. <b>Минимальной суммы на
                вывод нет</b>, можно выводить от 0.001 XCHNG.VIZ</Typography>
        <Typography>По любым вопроса можно обращаться в <Link href={'https://t.me/xchng_chat'} target={'_blank'}
                                                              rel={'nofollow'}>Telegram</Link>.</Typography>
    </>
}

export default GatewayGphViz